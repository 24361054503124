<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Employee<span class="text-danger">*</span></label>
                    <input type="text" id="nama" name="nama" v-model="currentData.nama" disabled>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="currentData.tanggal" class="picker" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Shift <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <!-- <datepicker v-model="currentData.shift" class="picker" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a> -->
                      <model-select :options="needData2" :settings="{ settingOption: value, settingOption: value }"
                              v-model="currentData.shift_id" class="custom-width form-control" placeholder="" ></model-select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Clock in<span class="text-danger">*</span></label>
                    <input type="time" id="jam_masuk" name="jam_masuk" v-model="currentData.jam_masuk">
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Clock out<span class="text-danger">*</span></label>
                    <input type="time" id="jam_keluar" name="jam_keluar" v-model="currentData.jam_keluar">
                  </div>
                </div>

                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Employee <span class="text-danger">*</span></label>
                    </div>
                    <multi-select :options="needData1" :selected-options="employees" style="z-index:504"
                      placeholder="select Employee" @select="onSelectEmployees">
                    </multi-select>
                  </div>
                </div> -->
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Update</button>
                  <router-link to="schedule" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-group input[type="datetime-local"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="time"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="datetime-local"]:focus {
  border: 1px solid rgba(82, 183, 127, 255);

}

.form-group input[type="time"]:focus {
  border: 1px solid rgba(82, 183, 127, 255);

}
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { ModelSelect, MultiSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {

    ModelSelect,
    MultiSelect

  },
  name: "editlocation",
  data() {
    return {
      filter: true,
      title: "Edit Schedule",
      title1: "edit schedule data",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentData: {
        shift_id: null,
      },

      ///multi select
      needData1: [],
      needData2: [],

      ///multi select
      // locations: [],
      employees: [],

      // lastSelectItemLocation: {},
      lastSelectItemEmployee: {},
    };
  },
  

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //get data


    // const location = JSON.parse(localStorage.getItem('locationList'));
    // location.forEach((value, index) => {
    //   const element = { id: value.id, value: value.id, text: value.nama_lokasi }
    //   this.LocationList.push(element);

    // });

    this.currentData = JSON.parse(localStorage.getItem('currentData'));

    this.preparedData();


  },

  watch: {
    "currentData.shift_id"(newIdShift) {
      // Find the selected shift's jam_masuk from needData2
      const selectedShift = this.needData2.find(
        (item) => item.value === newIdShift
      );
      if (selectedShift) {
        // Update the clock-in time with the selected shift's jam_masuk
        // this.currentData.jam_masuk = selectedShift.text.match(/\(([^-]*)/)[1].trim(); // Extracts jam_masuk
        
        const shiftTimes = selectedShift.text.match(/\(([^-]*) - ([^)]*)\)/);
        if (shiftTimes) {
          this.currentData.jam_masuk = shiftTimes[1].trim(); // Set jam_masuk
          this.currentData.jam_keluar = shiftTimes[2].trim(); // Set jam_keluar
        }
      }
    },
  },

  methods: {
    preparedData() {

      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      needData1.forEach((item, index) => {
        const item_prepare = { value: item.id, text: item.nama + ' (' + item.telepon + ')' };
        this.needData1.push(item_prepare);
      });

      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((item, index) => {
        const item_prepare = { value: item.id, text: item.nama_shift + ' (' + item.jam_masuk + ' - ' +  item.jam_pulang + ')'  };
        this.needData2.push(item_prepare);
      });

      const index = this.needData1.findIndex((record) => record.value == this.currentData.employee_id);
      const indexShift = this.needData2.findIndex((record) => record.value == this.currentData.shift_id);

      // console.log('cek shift id :', indexShift)
      // console.log('cek shift currentData :', this.currentData.shift_id)

      this.currentData.nama = this.needData1[index].text;
      this.currentData.shift_id = this.needData2[indexShift].value;

      this.currentData.tanggal = new Date(this.currentData.tanggal);

      
      this.employees = [];

    },
    onSelectEmployees(items, lastSelectItem) {
      this.employees = items
      this.lastSelectItemEmployee = lastSelectItem
    },

    insert() {
      ShowLoading();
      let formData = new FormData();
      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.delete("tanggal");
      if (this.currentData.tanggal != 'Invalid Date') {
        // var formattedDate = this.currentData.tanggal.toISOString().split('T')[0];
        var dateObj = new Date(this.currentData.tanggal);

        // Format the date manually (e.g., yyyy-mm-dd)
        var year = dateObj.getFullYear();
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
        var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits
        var formattedDate = `${year}-${month}-${day}`;

        formData.append("tanggal", formattedDate);
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // CloseLoading();
      // return false;

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'owners/schedule/update';
      } else {
        this.api = base_url + 'owners/schedule/update';
      }

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("schedule");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
